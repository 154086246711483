import Utility from "../../../shared/utility.js";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import breadcrumbComp from "../../common/breadcrumb-comp";
import usbService from "../js/usb-services.js";
export default {
  data() {
    return {
      refresh: true,
      backSlash: true,
      file: "",
      actionList: [],
      mainPage: "",
      subPage: "",
      pageUrl: "",
      favouriteList: [],
      showAdd: false,
      dragging: false,
      isHidden: true,
      isActive: true,
      uploadedFiles: [],
      selectedFile: [],
      uploadedFileDetails: [],
      uploadedFileList: [],
      process: "OrderUploadCSV",
      userId: EncryptUtility.localStorageDecrypt("userID"),
      noFiles: true,
      totalRecords: 0,
      itemDeleteDialog: false,
      recordDetails: [],
      headersUpload: [
        { text: "File Name", value: "OrgFileName", class: "primary customwhite--text", width: "15%" },
        { text: "Uploaded Date", value: "UploadDate", class: "primary customwhite--text", width: "20%" },
        { text: "Processed Date", value: "ProcessDate", class: "primary customwhite--text", width: "20%" },
        { text: "Status", value: "Status", class: "primary customwhite--text", width: "6%" },
        { text: "Uploaded By", value: "UploadedBy", class: "primary customwhite--text", width: "14%" },
        { text: "Comments", value: "Comments", class: "primary customwhite--text", width: "18%" },
        { text: "Actions", value: "Actions", class: "primary customwhite--text", width: "7%" },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        size: (value) => {
          if (value.size > 50e6) return "File size should be less than 50 MB!";
          else return true;
        },
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getUploadedFileList();
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.file = "";
      this.noFiles = true;
      this.uploadedFiles = [];
      this.totalRecords = 0;
      this.itemDeleteDialog = false;
      this.recordDetails = [];
      this.getUploadedFileList();
    },
    //List of uploaded files
    async getUploadedFileList() {
      this.uploadedFiles = [];
      let data = await usbService.getUploadedFiles("get", this.userId);
      if (data.message !== "NA") {
        const newArr = data.map((obj) => {
          return {
            ...obj,
            UploadDate: Utility.convertESTToLocal(obj.UploadDate),
            ProcessDate: obj.ProcessDate !== undefined ? Utility.convertESTToLocal(obj.ProcessDate) : "",
          };
        });
        this.uploadedFiles = newArr;
        this.totalRecords = newArr.length;
      }
    },
    //Delete File record
    deleteRecord(item) {
      this.itemDeleteDialog = true;
      this.recordDetails = [];
      this.recordDetails = item;
    },
    //Confirm Deletion
    confirmDeleteRecord() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .post(`/usb/parts_upload_delete?user_id=${this.userId}&fileID=${this.recordDetails.FileID}&UpdFileName=${this.recordDetails.UpdFileName}`)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.itemDeleteDialog = false;
            this.getUploadedFileList();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //File Upload
    uploadFile() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      const formData = new FormData();
      formData.append("file", this.file);
      this.noFiles = true;
      this.axios
        .post("usb/parts_upload?user_id=" + this.userId, formData)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.getUploadedFileList();
            this.file = "";
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Drag and Drop Start
    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        this.dragging = false;
        return;
      } else {
        this.noFiles = false;
        this.createFile(files[0]);
      }
    },
    //Checking file details
    createFile(file) {
      if (file.size > 50000000) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Size is more than 50MB",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.noFiles = true;
        this.dragging = false;
        return;
      }
      this.file = file;
      this.dragging = false;
    },
    //Removing dragged file
    removeFile() {
      this.file = "";
      this.noFiles = true;
    },
    //Downloading file API
    downloadFile(item) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("usb/parts_file_url?UpdFileName=" + item.UpdFileName)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            window.open(response.data.body, "_blank").focus();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
  },
};
